<template>
    <v-textarea v-if="isTextArea"
                :value="value"
                :label="field.label"
                readonly
                hide-details
                outlined
                :rows="field.label === 'Cover Letter' ? 6 : 3"></v-textarea>
    <v-checkbox v-else-if="isCheckBox"
                v-model="value"
                :label="field.label"
                readonly
                hide-details></v-checkbox>
    <v-text-field v-else-if="!isExcludedType"
                  :value="value"
                  :label="field.label"
                  readonly
                  hide-details
                  outlined></v-text-field>
</template>
<script>
    // see https://submittable.help/en/articles/904857-how-do-i-use-the-form-designer-when-creating-or-editing-a-form
    export default {
        name: 'field-submittable-v2',
        props: {
            field: {
                type: Object,
                required: true,
            },
        },
        computed: {
            fieldType() {
                return this.field.type;
            },
            isTextArea() {
                return this.fieldType === 'LONG_ANSWER';
            },
            isCheckBox() {
                return this.fieldType === 'SINGLE_CHECKBOX';
            },
            isExcludedType() {
                return this.field.label.toLowerCase().indexOf('self-identify') >= 0
                    || this.fieldType === 'FILE_UPLOAD';
            },
            value() {
                if (this.fieldType === 'MULTIPLE_RESPONSE' || this.fieldType === 'DROPDOWN_LIST')
                    return this.field.options.join(', ')

                if (this.fieldType === 'FILE_UPLOAD')
                    return '';
                
                return this.field.values[0].value;
            },
        },
    };
</script>