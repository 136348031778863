<template>
    <v-textarea v-if="isTextArea"
                :value="value"
                :label="field.label"
                readonly
                hide-details
                outlined
                :rows="field.field_type === 'Cover Letter' ? 6 : 3"></v-textarea>
    <v-checkbox v-else-if="isCheckBox"
                v-model="value"
                :label="field.label"
                readonly
                hide-details></v-checkbox>
    <v-text-field v-else-if="!isExcludedType"
                  :value="value"
                  :label="field.label"
                  readonly
                  hide-details
                  outlined></v-text-field>
</template>
<script>
    // see https://submittable.help/en/articles/904857-how-do-i-use-the-form-designer-when-creating-or-editing-a-form
    export default {
        name: 'field-submittable-v1',
        props: {
            field: {
                type: Object,
                required: true,
            },
        },
        computed: {
            fieldType() {
                return this.field.field_type;
            },
            isTextArea() {
                return this.fieldType === 'Cover Letter' 
                    || this.fieldType  === 'Text Area';
            },
            isCheckBox() {
                return this.fieldType === 'Checkbox';
            },
            isExcludedType() {
                return this.fieldType === 'File Upload'
                    && this.fieldType === 'Text Block';
            },
            value() {
                return this.isCheckBox
                    ? this.field.data.toLowerCase().trim() === 'true'
                    : this.field.data;
            },
        },
    };
</script>