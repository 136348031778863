<template>
    <div class="submittable-fields">
        <template v-for="field in fields">
            <field-submittable-v1 v-if="field.field_type"
                                  :key="field.form_field_id"
                                  :field="field"
                                  class="mb-4"></field-submittable-v1>
            <field-submittable-v2 v-else
                                  :key="field.id"
                                  :field="field"
                                  class="mb-4"></field-submittable-v2>
        </template>
    </div>
</template>
<script>
    import fieldSubmittableV1 from '@/components/field-submittable-v1';
    import fieldSubmittableV2 from '@/components/field-submittable-v2';

    export default {
        name: 'submittable-fields',
        components: {
            fieldSubmittableV1,
            fieldSubmittableV2
        },
        props: {
            fields: {
                type: Array,
                required: true,
            }
        }
    }
</script>